var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard"},[_c('promoter-link-modal',{ref:"promoterLinkModal"}),_c('div',{attrs:{"id":"Content"}},[_c('div',{staticClass:"shadow"}),_c('h1',{staticStyle:{"visibility":"hidden","height":"0px","margin":"0"}},[_vm._v("Events Dashboard")]),_c('div',{staticClass:"content-inner"},[(false)?_c('div',{staticClass:"title-block"},[_c('h3',{attrs:{"id":"Event-Title"}},[_vm._v("All Events Combined Statistics Overview")]),_c('button',{attrs:{"id":"Reset-Btn"},on:{"click":_vm.loadCombinedStatistics}},[_vm._v(" Reset ")])]):_vm._e(),(false)?_c('div',{staticClass:"chart-container"},[_c('div',{staticClass:"chart-wrapper"},[_c('small-chart',{attrs:{"id":"clicks","title":"Link Clicks","change":_vm.changes.clicks,"total":_vm.totals.clicks},on:{"input":function($event){return _vm.setChartTimeframe(
                _vm.charts.clicks,
                function (data) { return data.clicks; },
                arguments[0]
              )}}}),_c('small-chart',{attrs:{"id":"promo-code","title":"Promo Code Usage","change":_vm.changes.promo_codes,"total":_vm.totals.promo_codes},on:{"input":function($event){return _vm.setChartTimeframe(
                _vm.charts.promo_codes,
                function (data) { return data.promo_codes; },
                arguments[0]
              )}}}),_c('small-chart',{attrs:{"id":"earnings","title":"Earned For Planner","change":_vm.changes.earnings,"total":_vm.totalEarnedForPlannerCurrencyChange},on:{"input":function($event){return _vm.setChartTimeframe(
                _vm.charts.earnings,
                function (data) { return data.earnings; },
                arguments[0]
              )}}}),_vm._l((_vm.tickets[0]),function(tier,index){return _c('small-chart',{key:tier.name,attrs:{"id":("tickets-0-" + index),"classes":['ticket-graph'],"title":("Ticket Sales (" + (tier.name.trim()) + ")"),"change":_vm.changes.tickets[0][index],"total":_vm.totals.tickets[0][index]},on:{"input":function($event){return _vm.setChartTimeframe(
                _vm.charts.tickets[0][index],
                function (data) { return data.tickets[0][index]; },
                arguments[0]
              )}}})})],2),_c('div',{staticClass:"chart-wrapper"},[_c('div',{staticClass:"chart-medium"},[_c('div',{staticClass:"chart-header"},[_c('p',[_vm._v(" Statistics "),_c('span',{staticClass:"dark-text"},[_vm._v("- "+_vm._s(_vm.statisticsTimeframe))])]),_c('div',[_c('chart-timeframe-selector',{attrs:{"options":_vm.timeframeOptions},on:{"input":function($event){_vm.setChartTimeframeStatistics(
                      _vm.charts.statistics,
                      arguments[0]
                    ),
                      _vm.setChartTimeframeText(
                        'statisticsTimeframe',
                        arguments[1]
                      )}}})],1)]),_vm._m(0)]),_c('small-chart',{attrs:{"id":"tickets-quantity","title":"Ticket Sales (Quantity)","change":_vm.changes.ticketsQuantity,"total":_vm.totals.ticketsQuantity},on:{"input":function($event){return _vm.setChartTimeframe(
                _vm.charts.ticketsQuantity,
                function (data) { return data.ticketsQuantity; },
                arguments[0]
              )}}}),_vm._l((_vm.tickets[1]),function(tier,index){return _c('small-chart',{key:tier.name,attrs:{"id":("tickets-1-" + index),"classes":['ticket-graph'],"title":("Ticket Sales (" + (tier.name.trim()) + ")"),"change":_vm.changes.tickets[1][index],"total":_vm.totals.tickets[1][index]},on:{"input":function($event){return _vm.setChartTimeframe(
                _vm.charts.tickets[1][index],
                function (data) { return data.tickets[1][index]; },
                arguments[0]
              )}}})})],2),_c('div',{staticClass:"chart-wrapper"},[_c('div',{staticClass:"chart-medium"},[_c('div',{staticClass:"chart-header"},[_c('p',[_vm._v(" Total Commission "),_c('span',{staticClass:"dark-text"},[_vm._v("- "+_vm._s(_vm.commissionTimeframe))])]),_c('div',[_c('chart-timeframe-selector',{attrs:{"options":_vm.timeframeOptions},on:{"input":function($event){_vm.setChartTimeframe(
                      _vm.charts.commission,
                      function (data) { return data.earnings; },
                      arguments[0]
                    ),
                      _vm.setChartTimeframeText(
                        'commissionTimeframe',
                        arguments[1]
                      )}}})],1)]),_c('div',{staticClass:"chart-values"},[_c('div',{staticClass:"chart-direction",class:[_vm.changes.commission.class]},[(_vm.changes.commission.class === 'increase')?_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":"arrow-up"}}):(_vm.changes.commission.class === 'decrease')?_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":"arrow-down"}}):_c('font-awesome-icon',{staticClass:"icon",attrs:{"icon":"minus"}}),_c('p',[_vm._v(_vm._s(_vm.changes.commission.value))])],1),_c('div',{staticClass:"chart-totals"},[_c('h4',[_vm._v(" "+_vm._s(_vm.totalCommissionCurrencyChange)+" ")])])]),_vm._m(1)]),_c('small-chart',{attrs:{"id":"tickets-revenue","title":"Ticket Sales (Revenue)","change":_vm.changes.ticketsRevenue,"total":_vm.totalTicketsRevenueCurrencyChange},on:{"input":function($event){return _vm.setChartTimeframe(
                _vm.charts.ticketsRevenue,
                function (data) { return data.ticketsRevenue; },
                arguments[0]
              )}}}),_vm._l((_vm.tickets[2]),function(tier,index){return _c('small-chart',{key:tier.name,attrs:{"id":("tickets-2-" + index),"classes":['ticket-graph'],"title":("Ticket Sales (" + (tier.name.trim()) + ")"),"change":_vm.changes.tickets[2][index],"total":_vm.totals.tickets[2][index]},on:{"input":function($event){return _vm.setChartTimeframe(
                _vm.charts.tickets[2][index],
                function (data) { return data.tickets[2][index]; },
                arguments[0]
              )}}})})],2)]):_vm._e(),(false)?_c('div',{staticClass:"divider"}):_vm._e(),_c('tab-tote',{attrs:{"tabs":_vm.navTabs,"value":_vm.navTabSelected},on:{"click":_vm.onClickNavTab}}),_c('div',{staticClass:"divider"}),_c('router-view',{attrs:{"pageURL":_vm.link}})],1)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chart-chart-full"},[_c('canvas',{attrs:{"id":"statistics","width":"200px","height":"165px"}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chart-chart"},[_c('canvas',{attrs:{"id":"commission","width":"200px","height":"120px"}})])}]

export { render, staticRenderFns }