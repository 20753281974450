<template>
  <TTDialog title="Promoter Link" :active="active" v-on:close="close">
    <template v-if="canLink">
      <div class="info-text">Here is your promoter link for this event:</div>
      <div class="link-section">
        <div class="link">{{ link }}</div>
      </div>
    </template>
    <template v-else>
      The event planner has not provided you with a promoter link for this
      event.
    </template>
    <template v-if="canLink" v-slot:footer>
      <div class="modal-footer">
        <v-btn
          class="link-btn"
          :ripple="false"
          color="var(--primary)"
          dark
          @click="copyLink"
        >
          <font-awesome-icon icon="copy" />
          &nbsp; Copy to Clipboard
        </v-btn>
        <v-btn
          class="link-btn"
          :ripple="false"
          color="var(--primary)"
          dark
          @click="openLink"
        >
          Open Link
        </v-btn>
      </div>
    </template>
  </TTDialog>
</template>

<script>
import TTDialog from "@/components/TTDialog.vue";
import DiyoboInput from "@/components/DiyoboInput";
import DiyoboButton from "@/components/DiyoboButton";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { saveToClipboard } from "@/helpers/clipboard";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

library.add(faCopy);

export default {
  name: "promoter-link-modal",
  components: {
    TTDialog,
    DiyoboInput,
    DiyoboButton,
    FontAwesomeIcon
  },
  data() {
    return {
      link: "",
      active: false,
      canLink: false
    };
  },
  methods: {
    open(event) {
      this.canLink = event.promoter.link;
      if (this.canLink) {
        if (event.url === "go-pro-academy") {
          this.link =
            "https://goproacademy.com/?gpa=" + this.$store.state.user.pv_url;
        } else if (event.url === "go-pro-challenge") {
          this.link =
            "https://mygoprochallenge.com/" + this.$store.state.user.pv_url;
        } else if (event.url === "go-pro-recruiting-challenge-replay") {
          this.link =
            "https://mygoprochallenge.com/" + this.$store.state.user.pv_url;
        } else if (event.url === "wake-up-call-49974589893") {
          this.link =
            "http://2023wakeup.com/?nmp=" + this.$store.state.user.pv_url;
        } else if (event.url === "go-pro-experience-nevada-19551963128") {
          this.link =
            "https://www.gopro2023.com/gpx-2023?nmp=" +
            this.$store.state.user.pv_url;
        } else if (event.url === "wake-up-call-replay-68028721048") {
          this.link =
            "http://2023wakeup.com/replay?nmp=" + this.$store.state.user.pv_url;
        } else if (event.cf_event_url) {
          this.link =
            `${event.cf_event_url}?nmp=` + this.$store.state.user.pv_url;
        } else {
          this.link = this.createBuyersLink(
            event.url +
              (event.promoter.link ? "?pv=" + event.promoter._id.$oid : ""),
            event.whitelabel
          );
        }
      }

      // this.$refs.modal.style.display = "block";
      // document.body.style.overflow = "hidden";

      setTimeout(() => {
        this.active = true;
      }, 250);
    },
    copyLink() {
      saveToClipboard(this.link, this.$root.$refs.alert);
      this.$toast("Promoter linked copied!", { type: "success" });
      this.close();
    },
    openLink() {
      window.open(this.link, "_blank");
      this.close();
    },
    close() {
      this.active = false;

      setTimeout(() => {
        document.body.style.overflow = "";
        this.link = "";
      }, 500);
    }
  }
};
</script>

<style lang="less" scoped>
.link-section {
  background-color: var(--content-dark);
  margin: 20px 0;
  padding: 5px 10px;
  border-radius: 8px;
}

.modal-footer {
  display: flex;
  justify-content: space-evenly !important;
  gap: 8px;
}

.link-btn {
  @media screen and (max-width: 850px) {
    font-size: 12px;
    font-weight: bold;
  }
}
</style>
